import AnalyticsProvider from "./src/contexts/analytics/provider";
import AuthProvider from "./src/contexts/auth/provider";
import CartProvider from "./src/contexts/cart/provider";
import LoyaltyProvider from "./src/contexts/loyalty/provider";
import DeliveryEstimateProvider from "./src/contexts/delivery-estimate/provider";
import ErrorNotifierProvider from "./src/contexts/error-notifier/provider";
import { InstantSearchProvider } from "./src/components/instant-search/InstantSearchProvider";
import { LocationProvider } from "@reach/router";
import OneClickCheckoutProvider from "./src/contexts/one-click-checkout/provider";
import { ThemeProvider } from "@vinomofo/components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorNotifierProvider>
      <LocationProvider>
        <AuthProvider>
          <QueryClientProvider client={new QueryClient()}>
            <LoyaltyProvider>
              <OneClickCheckoutProvider>
                <AnalyticsProvider>
                  <CartProvider>
                    <DeliveryEstimateProvider>
                      <ThemeProvider>
                        <InstantSearchProvider>{element}</InstantSearchProvider>
                      </ThemeProvider>
                    </DeliveryEstimateProvider>
                  </CartProvider>
                </AnalyticsProvider>
              </OneClickCheckoutProvider>
            </LoyaltyProvider>
          </QueryClientProvider>
        </AuthProvider>
      </LocationProvider>
    </ErrorNotifierProvider>
  );
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state?.refinementList) {
    return false;
  }
  return true;
};

export const onRouteUpdate = (route) => {
  window.setTimeout(function () {
    window.analytics &&
      window.analytics.page(document.title, {
        path: route.location.pathname,
        referrer: route.prevLocation ? route.prevLocation.href : "",
      });
  }, 50);
};
