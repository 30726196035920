import { InstantSearchState, initialState } from "./state";

import { createContext } from "react";

export interface InstantSearchContextInterface extends InstantSearchState {
  doPreSearch?: (kewords: string) => void;
  onSearchStateChange?: (state: any) => void;
  setCarryOverFilter?: (filter: string) => void;
  currentFilterGroup?: string;
  toggleFilterGroup?: (id: string) => void;
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <InstantSearchProvider>"
  );
};

const initialContext = {
  ...initialState,
  doPreSearch: stub,
  onSearchStateChange: stub,
  setCarryOverFilter: stub,
};

export const InstantSearchContext = createContext<
  InstantSearchContextInterface
>(initialContext);
