import {
  HubSpotContactDTO,
  HubSpotContactPropertiesDTO,
} from "../../../api/src/services/hubspot-service";
import LoyaltyContext, {
  LoyaltyContextInterface,
} from "../../contexts/loyalty/context";
import { RequestParams, useRequest } from "../use-request";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "@reach/router";

import useAuth from "../../hooks/use-auth";

export interface LoyaltyInterface {
  isLoading: boolean;
  loyalty: HubSpotContactPropertiesDTO | null;
  showLoyalty?: boolean;
  error?: string;
}

type HookParams = {
  getTokenID: () => Promise<string>;
};

const API_URL = `/api/get-hubspot-contact`;

export const useLoyaltyContext = (): LoyaltyContextInterface =>
  useContext(LoyaltyContext);

export default useLoyaltyContext;

export const useLoyalty = (): LoyaltyInterface => {
  const { getTokenID } = useAuth();
  const location = useLocation();
  const isLoyaltyFeatureEnabled = process.env.GATSBY_LOYALTY_ENABLED === "true";
  const [loyalty, setLoyalty] = useState<HubSpotContactPropertiesDTO | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const request = isLoyaltyFeatureEnabled
    ? useRequest<HubSpotContactDTO>(createRequestParams({ getTokenID }))
    : null;

  const baseIndexName = process.env.GATSBY_ALGOLIA_OFFERS_INDEX ?? "";

  const isWineDetailsPage = () => {
    const pathRegex = /^\/wines\/[^/]+\/[^/]+\/?$/; // Matches /wines/{category}/{wineSlug}
    return pathRegex.test(location.pathname);
  };

  useEffect(() => {
    let indexName = baseIndexName;
    const searchParams = new URLSearchParams(location?.search ?? "");
    const sortBy = searchParams.get("sortBy") ?? indexName;
    indexName = sortBy;
    if (loyalty?.loyalty_tier) {
      indexName = indexName.replaceAll(
        `site_offers`,
        `site_${loyalty.loyalty_tier.toLowerCase()}_offers`
      );
    }
    if (!isWineDetailsPage()) {
      localStorage.setItem("algoliaIndexName", indexName);
    }
  }, [loyalty, location.search]);

  useEffect(() => {
    if (request) {
      setIsLoading(request.isLoading);
      if (request.data) {
        setLoyalty(request.data.properties);
      }
      if (request.error) {
        setError(request.error);
      }
    }
  }, [request]);

  const showLoyalty = useMemo(() => {
    if (!isLoyaltyFeatureEnabled || !loyalty) {
      return false;
    }

    const { loyalty_eligible_for_loyalty, loyalty_tier } = loyalty;
    return (
      loyalty_eligible_for_loyalty?.toString() === "true" &&
      loyalty_tier !== null
    );
  }, [isLoyaltyFeatureEnabled, loyalty]);

  return {
    isLoading,
    loyalty,
    showLoyalty,
    error,
  };
};

const createRequestParams = ({ getTokenID }: HookParams): RequestParams => ({
  url: API_URL,
  getTokenID,
});
