import { AnalyticsContext, AnalyticsContextInterface } from "./context";
import { ReactNode, useEffect } from "react";

import { initialAnalyticsState } from "../analytics/state";
import useAuth from "../../hooks/use-auth";

interface AnalyticsProviderProps extends JSX.Element {
  children: ReactNode;
}

const AnalyticsProvider = ({
  children,
}: AnalyticsProviderProps): JSX.Element => {
  const { user, isLoading } = useAuth();

  const identify = () => {
    if (user) {
      if (
        typeof window !== "undefined" &&
        typeof window.analytics !== "undefined"
      ) {
        window.analytics.identify(
          process.env.GATSBY_VM_MARKET +
            "-" +
            user?.["https://www.vinomofo.com/app_metadata"]?.[
              `${process.env.GATSBY_VM_MARKET}_EXTERNAL_ID`
            ],
          {
            name: user?.name,
            email: user?.email,
          }
        );
        window.analytics.track("User Identified", {
          category: "Auth",
          userId:
            process.env.GATSBY_VM_MARKET +
            "-" +
            user?.[`https://www.vinomofo.com/app_metadata`]?.[
              `${process.env.GATSBY_VM_MARKET}_EXTERNAL_ID`
            ],
          email: user?.email,
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      identify();
    }
  }, [isLoading]);

  const ctx: AnalyticsContextInterface = {
    ...initialAnalyticsState,
    identify,
  };
  return (
    <AnalyticsContext.Provider value={ctx}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
