import { Event } from "../types";
import { Honeybadger } from "@honeybadger-io/react";
import configData from "../../../config.json";

const serviceName =
  configData[process.env.VM_MARKET || process.env.GATSBY_VM_MARKET].DD_SERVICE;
const reportData = process.env.VM_ENV=== "production";
const config = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  developmentEnvironments: ["development"],
  reportData: reportData,
  filters: ["password", "creditcard"],
};
const isProd = process.env.NODE_ENV !== "development";

isProd ? Honeybadger.configure(config) : null;

export const ErrorNotifier = {
  notify: (error: unknown, context: Record<string, unknown>): void => {
    try {
      if (isProd) Honeybadger.notify(error as any, context);
    } catch(e) {
      console.log(e);
    }
  },
  setContext: (context: Record<string, unknown>): void => {
    try {
      if (isProd) Honeybadger.setContext(context);
    } catch(e) {
      console.log(e);
    }
  },
};

interface contextBuilderResponse {
  requestId: string;
  user_id: string;
  path: string;
  method: string;
  requestBody: string | null;
}

interface EventQueryStringParameters {
  [name: string]: string | undefined;
}
interface EventMultiValueQueryStringParameters {
  [name: string]: string[] | undefined;
}

export const contextBuilder = (
  ctx: any,
  evt: Event
): contextBuilderResponse => {
  return {
    ...(ctx && {
      requestId: ctx && ctx.awsRequestId,
      user_id:
        ctx &&
        ctx.clientContext &&
        ctx.clientContext.user &&
        ctx.clientContext.user.sub,
    }),
    ...(evt && {
      queryStringParameters: evt.queryStringParameters,
      multiValueQueryStringParameters: evt.multiValueQueryStringParameters,
      path: evt.path,
      method: evt.httpMethod,
      requestBody: evt.body,
    }),
  };
};

export const errorDataBuilder = (
  type: string,
  statusCode: number,
  error: unknown,
  context?: any,
  event?: Event,
  customMessage?: string
): {
  tags: string;
  name: string;
  message: string;
  environment: string;
  context: contextBuilderResponse;
  params: EventQueryStringParameters | EventMultiValueQueryStringParameters;
} => {
  return {
    tags: `${process.env.VM_MARKET}, ${type}, ${serviceName}, ${statusCode}`,
    name: `[${type}][${serviceName}]`,
    message: `[${statusCode}] ${customMessage || error}`,
    context: {
      ...contextBuilder(context, event),
    },
    environment: `${process.env.NODE_ENV}[${process.env.VM_MARKET}]`,
    params:
      (event && event.queryStringParameters) ||
      (event && event.multiValueQueryStringParameters),
  };
};


export const errorDataBuilderV2 = (
  type: string,
  statusCode: number,
  error: any,
  context?: any,
  event?: Event,
  customMessage?: string
): {
  tags: string;
  name: string;
  message: string;
  environment: string;
  context: contextBuilderResponse;
  params: EventQueryStringParameters | EventMultiValueQueryStringParameters;
} => {
  return {
    tags: `${process.env.VM_MARKET}, ${type}, ${serviceName}, ${statusCode}`,
    name: `[${type}][${serviceName}]`,
    message: `[${statusCode}] ${customMessage || error.response.data.title}`,
    context: {
      ...contextBuilder(context, event),
    },
    environment: `${process.env.NODE_ENV}[${process.env.VM_MARKET}]`,
    params:
      (event && event.queryStringParameters) ||
      (event && event.multiValueQueryStringParameters),
  };
};
