import { AuthState } from "./state";

export const reducer = (
  state: AuthState,
  action: { type: string } & AuthState
): AuthState => {
  switch (action.type) {
    case "SET_AUTH0_STATE":
      return {
        ...state,
        isLoading: action.isLoading,
        isAuthenticated: action.isAuthenticated,
        error: action.error,
      };
    case "SET_USER":
      return { ...state, user: action.user };
    case "SETTING_REFERRAL_CODE":
      return { ...state, checkingReferralCode: true };
    case "SET_REFERRAL_CODE":
      return {
        ...state,
        customerReferralCode: action.customerReferralCode,
        checkingReferralCode: false,
      };
    case "SETTING_FAVOURITES":
      return { ...state, fetchingFavourites: true };
    case "SET_FAVOURITES":
      return {
        ...state,
        favourites: action.favourites,
        fetchingFavourites: false,
      };
    // case "SET_ISLOADING":
    //   return { ...state, isLoading: action.isLoading };
    // case "SET_ISAUTHENTICATED":
    //   return { ...state, isAuthenticated: action.isAuthenticated };
    // case "SET_ERROR":
    //   return { ...state, error: action.error };

    default:
      return state;
  }
};
