import * as React from "react";

import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

const reportData = process.env.GATSBY_VM_ENV === "production";

const baseHoneyBadgerConfig = {
  apiKey: process.env.GATSBY_HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  developmentEnvironments: ["development"],
  reportData: reportData,
  filters: ["password", "creditcard"],
}

const standardConfig = {
  enableUncaught: false,
  maxErrors: 5
};


const honeyBadgerConfig = typeof(window) === 'undefined' ?  baseHoneyBadgerConfig : {...baseHoneyBadgerConfig, ...standardConfig};

const isProd = process.env.NODE_ENV !== "development";
const EmptyComponent = () => null;

const ErrorNotifierProvider = ({
  ErrorComponent = EmptyComponent,
  children,
}: {
  ErrorComponent?: React.ReactNode;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      {isProd ? (
        <HoneybadgerErrorBoundary
          honeybadger={isProd ? Honeybadger.configure(honeyBadgerConfig) : null}
          ErrorComponent={ErrorComponent}
        >
          {children}
        </HoneybadgerErrorBoundary>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorNotifierProvider;
