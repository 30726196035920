import { CartItem } from "../../contexts/cart/types";
import config from "../../../config.json";
import { SegmentProduct } from "../../types/segment-types";
import { VinoDataOffer } from "../../types/vino-data-types";

export const segmentProductOffer = (offer: VinoDataOffer): SegmentProduct => {
  return {
    search_index: offer.search_index,
    eventType: offer.eventType,
    objectID: offer.objectID || offer.offerId || offer.id,
    brand: offer.brand,
    cartId: offer.cartId,
    category: offer.category,
    spirit: offer.isSpirit,
    currency: config[process.env.GATSBY_VM_MARKET]?.VM_CURRENCY_CODE || "AUD",
    discount: offer.discountPercentage,
    image_url: offer.imageUrl || offer.cardImage?.url,
    inventoryStatus: offer.inventoryStatus,
    market: process.env.VM_MARKET || process.env.GATSBY_VM_MARKET,
    mixed: offer.isMixed,
    name: offer.name,
    url: offer.url,
    pack_size: offer.packSize,
    preservatives: offer.preservatives && offer.preservatives.join("|"),
    price: offer.packPrice,
    product_id: offer.objectID || offer.offerId || offer.id,
    quantity: 1,
    country: offer.country,
    region: offer.region,
    rrp: offer.packRRP,
    sku: offer.sku,
    style: offer.offerStyle,
    total: offer.total,
    variant: offer.offerVariant,
    varietal: offer.varietal,
    vintage: offer.vintage,
    position: parseInt(offer.__position),
    slot: offer.slot || "",
  };
};

export const segmentProductFromVinoDataOffer = (
  offer: VinoDataOffer,
  offerUrl: string
) => {
  const sku = offer.sku;
  const wine = sku.wines[0];
  const data = {
    product_id: offer.id,
    sku: sku.code,
    name: offer.name,
    category: sku.skuType === "MultiWineSKU" ? "mixed" : wine?.category,
    brand: sku.skuType === "MultiWineSKU" ? "various" : wine.brand,
    variant: sku.packSize,
    price: offer.packPrice,
    quantity: 1,
    currency: "AUD",
    url: offerUrl,
    image_url: offer.cardImage ? offer.cardImage.url : "",
  };
  return data;
};

export const segmentProductFromCartItem = (
  cartItem: CartItem,
  cartId?: string
) => {
  let data = {
    product_id: cartItem.productId,
    sku: cartItem.sku,
    quantity: cartItem.quantity,
    name: cartItem.name,
    image_url: cartItem.imageUrl,
    price: cartItem.salePrice,
  };
  if (cartId)
    data = {
      ...data,
      ...{
        cart_id: cartId,
        market: process.env.VM_MARKET || process.env.GATSBY_VM_MARKET,
      },
    };
  return data;
};
