import { createContext } from "react";

export interface DeliveryEstimateContextInterface {
  readonly error?: string;
  readonly postcode?: string;
  readonly searching?: boolean;
  readonly deliveryEstimate?: string;
  readonly upperDeliveryEstimate?: string;

  resetDeliveryEstimate: () => void;
  setPostcode: (postcode: string) => void;
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <DeliveryEstimateProvider>"
  );
};

const initialDeliveryEstimateContext: DeliveryEstimateContextInterface = {
  setPostcode: stub,
  resetDeliveryEstimate: stub,
};

const DeliveryEstimateContext = createContext(initialDeliveryEstimateContext);

export default DeliveryEstimateContext;
