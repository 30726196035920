import { createContext } from "react";

import { AnalyticsState, initialAnalyticsState } from "./state";

export interface AnalyticsContextInterface extends AnalyticsState {
  identify: () => void;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <AnaltyicsProvider>");
};

const initialContext = {
  ...initialAnalyticsState,
  identify: stub,
};

export const AnalyticsContext = createContext<AnalyticsContextInterface>(
  initialContext
);
