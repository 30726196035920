import {
  ErrorNotifier,
  errorDataBuilder,
} from "../../../api/src/utils/error-notifier";

import { VinoUser } from "./state";
import config from "../../../config.json";
import vinoFetch from "../../utils/vinoFetch";

const market = process.env.GATSBY_VM_MARKET;
const configData = config[market];

interface AuthResolverInterface {
  validateEmail: (email: string) => Promise<{ exists: boolean; error?: Error }>;
  updateProfile: (
    payload: {
      given_name?: string;
      family_name?: string;
      phone?: string;
      birthdate?: string;
    },
    tokenid: string
  ) => Promise<{ user?: VinoUser; error?: Error }>;
  changeEmailStepTwo: (
    payload: { email: string },
    token: string
  ) => Promise<{ error?: Error }>;
  changeEmailStepThree: (
    payload: { email: string; otp: string },
    token: string
  ) => Promise<{ error?: Error }>;
  hasSocialLogin: (
    email: string
  ) => Promise<{ isSocial: boolean; error?: Error }>;
}

export const AuthResolver: AuthResolverInterface = {
  validateEmail: async (email) => {
    try {
      const { data } = await vinoFetch.post(
        `${
          configData.IDENTITY_HOST[process.env.GATSBY_VM_ENV]
        }/api/validate-email`,
        {
          email,
        }
      );
      console.log("AuthResolver DATA ===> ", JSON.stringify(data));

      return { exists: data?.status };
    } catch (error) {
      console.log("AuthResolver ERROR ===> ", JSON.stringify(error));
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  updateProfile: async (payload, tokenid) => {
    try {
      const { data } = await vinoFetch.get("/api/update-auth0-user-metadata", {
        params: {
          given_name: payload?.given_name,
          family_name: payload?.family_name,
          user_metadata: JSON.stringify(payload),
        },
        headers: {
          authorization: `Bearer ${tokenid}`,
        },
      });

      return { user: data.data };
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  hasSocialLogin: async (
    email: string
  ): Promise<{ isSocial?: boolean; error?: Error }> => {
    try {
      const response = await vinoFetch.post(
        `${
          configData.IDENTITY_HOST[process.env.GATSBY_VM_ENV]
        }/api/check-social-identity`,
        {
          email,
        }
      );
      if (response.status === 200) {
        return { isSocial: response.data?.isSocial };
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error(error);
      return { isSocial: false };
    }
  },
  changeEmailStepTwo: async (payload, token) => {
    try {
      await vinoFetch.post(
        `${
          configData.IDENTITY_HOST[process.env.GATSBY_VM_ENV]
        }/api/change-email__step-2`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );

      return {};
    } catch (error) {
      if (error.response.status === 409) {
        const message =
          error.response?.data?.message ?? "Sorry, something went wrong!";
        ErrorNotifier.notify(error, errorDataBuilder("FE", 409, error));
        return {
          error: {
            message,
          },
        };
      }
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  changeEmailStepThree: async (payload, token) => {
    try {
      await vinoFetch.post(
        `${
          configData.IDENTITY_HOST[process.env.GATSBY_VM_ENV]
        }/api/change-email__step-3`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );

      return {};
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
};
