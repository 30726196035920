export type RefinementFacet = {
  facetType: "RefinementFacet" | "MultiRangeFacet" | "FilterOnlyFacet";
  attribute: string;
  label: string;
  urlKey: string;
  items?: Array<MultiRangeFacetItem>;
  pillsLabel?: string;
  slug?: string;
  audience?: string[];
};

type MultiRangeFacetItem = {
  label: string;
  start?: number;
  end?: number;
};

const MARKET = process.env.GATSBY_VM_MARKET;

// These facets will display in the refinements list on search results
// in the order they are defined here
export const ALL_FACETS: Array<RefinementFacet> = [
  {
    facetType: "RefinementFacet",
    attribute: "priceRange",
    label: "Bottle price",
    urlKey: "priceRange",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "categories",
    label: "Category",
    urlKey: "category",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "varietals",
    label: "Varietal",
    urlKey: "varietal",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "countries",
    label: "Country",
    urlKey: "country",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "regions",
    label: "Region",
    urlKey: "region",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "vintage",
    label: "Vintage",
    urlKey: "vintage",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "packSize",
    label: "Pack size",
    urlKey: "packSize",
    audience: ["AU", "SG"],
  },
  {
    facetType: "RefinementFacet",
    attribute: "tags",
    label: "Collections",
    urlKey: "tags",
    audience: ["AU", "SG"],
  },
  {
    facetType: "FilterOnlyFacet",
    attribute: "secret",
    label: "Black Market",
    urlKey: "black-market",
    pillsLabel: "No Black Market",
    slug: "black-market",
    audience: ["AU"],
  },
  {
    facetType: "FilterOnlyFacet",
    attribute: "isMixed",
    label: "Mixed",
    urlKey: "isMixed",
    pillsLabel: "No Mixed Cases",
    slug: "mixed-cases",
    audience: ["AU", "SG"],
  },
];

export const RefinementFacets = ALL_FACETS.filter(
  ({ facetType }) => facetType === "RefinementFacet"
);

export const MultiRangeFacets = ALL_FACETS.filter(
  ({ facetType }) => facetType === "MultiRangeFacet"
);

export const FilterOnlyFacets = ALL_FACETS.filter(
  ({ facetType }) => facetType === "FilterOnlyFacet"
);
