import {
  ICheckout,
  IGetAddresses,
  IGetStoredInstruments,
  IProcessPayment,
} from "../../../api/src/services/vino-api-services";

import { createContext } from "react";

// import { initialOneClickCheckoutState } from "./state";

export interface OneClickCheckoutInterface {
  isProcessing?: boolean;
  isBuying?: boolean;
  isRedirecting?: boolean;
  processMessage?: string;
  error?: any;
  setRedirecting?: (state: boolean) => void;
  resetState?: () => void;
  getAddressesByCustomerId: ({
    customerID,
    market,
  }: IGetAddresses) => Promise<any>;
  getStoredInstrumentsByCustomerId: ({
    customerID,
    market,
    defaultOnly,
  }: IGetStoredInstruments) => Promise<any>;
  doOneClickCheckout: ({
    customerID,
    market,
    cartId,
    cart,
  }: ICheckout) => Promise<any>;
  // oneClickCheckoutP1: ({
  //   customerID,
  //   market,
  //   cartId,
  // }: ICheckout) => Promise<any>;
  // oneClickCheckoutP2: ({
  //   customerID,
  //   market,
  //   cartId,
  // }: ICheckout) => Promise<any>;
  processPayment: ({
    PAT_TOKEN,
    market,
    paymentData,
  }: IProcessPayment) => Promise<any>;
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <OneClickCheckoutProvider>"
  );
};

const initialOneClickCheckoutContext: OneClickCheckoutInterface = {
  // ...initialOneClickCheckoutState,
  setRedirecting: stub,
  resetState: stub,
  getAddressesByCustomerId: stub,
  getStoredInstrumentsByCustomerId: stub,
  doOneClickCheckout: stub,
  // oneClickCheckoutP1: stub,
  // oneClickCheckoutP2: stub,
  processPayment: stub,
};

export const OneClickCheckoutContext = createContext<OneClickCheckoutInterface>(
  initialOneClickCheckoutContext
);

export default OneClickCheckoutContext;
