import { Cart, CartItem } from "./types";

export interface CartState {
  error?: Error;
  isLoading: boolean;
  isMerging: boolean;
  changeCartOnMerge: boolean;
  fetchingCart?: boolean;
  isRedirecting: boolean;
  cart?: Cart;
  isCartOpen?: boolean;
  geoLocRegion?: string;
  soldOutProducts?: Array<CartItem>;
}

export const initialCartState: CartState = {
  isMerging: false,
  changeCartOnMerge: false,
  isLoading: false,
  fetchingCart: false,
  isRedirecting: false,
  isCartOpen: false,
  geoLocRegion: "",
};
