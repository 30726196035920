import { IGatsbyImageData } from "gatsby-plugin-image";
import { ReactNode } from "react";
import { TrackingEventName } from "./utils/event-tracking";
import { VinoDataSKU, VinoDataOffer } from "./types/vino-data-types";

export type Facets = { [group: string]: string | boolean };
export type RefinementOptions = {
  [group: string]: {
    [key: string]: number;
  };
};

export interface Trackable {
  trackEvent?: (eventName: TrackingEventName, object?: any) => void;
}

export type ResultMetadata = {
  id: string;
  indexName: string;
  pageNumber?: number;
  hitsPerPage?: number;
  totalCount?: number;
};

export type Filters = {
  q?: string;
  sort?: string;
  page?: string;
  perPage?: string;
  [key: string]: string;
};

type RawResult = {
  is_mixed: boolean;
  pack_size: number;
  offer_price: number;
  unit_price: number;
  pack_rrp?: number;
  unit_rrp?: number;
  card_image_url?: string;
  thumbnail_image_url?: string;
  name: string;
  region: string;
  discount?: string;
};

export type RawResults = Array<RawResult>;

export type NavigationItem__Route = {
  title: string;
  linkTo: { slug: string; path: string };
  primaryColor: string;
  secondaryColor: string;
};

export type NavigationItem__Link = {
  title: string;
  linkTo: { url: string };
  primaryColor: string;
  secondaryColor: string;
};

export type NavigationItem = NavigationItem__Route | NavigationItem__Link;

export const isNavigationItemWithRoute = (
  item: NavigationItem
): item is NavigationItem__Route => {
  return (item as NavigationItem__Route).linkTo.slug !== undefined;
};

export const navigationItemLinkTo = (item: NavigationItem): string => {
  return isNavigationItemWithRoute(item) ? item.linkTo.slug : item.linkTo.url;
};

export type NavigationItems = Array<NavigationItem>;

export type UrgencyMessage = {
  status:
    | "ALMOST_GONE"
    | "SELLING_FAST"
    | "NEW_TO_THE_FO"
    | "POPULAR_OFFER"
    | "SOLD_OUT";
  description: string;
  backgroundColor: string;
};

export type Availability = "available" | "sold_out" | "pre_sale";
export type Badge = { image: StorefrontImage; label: string; count?: number };
export type Award = { image: StorefrontImage; label: string; count: number };
export type Variety = { name: string; wineCategory: WineCategory };
export type WineCategory = { name: string };
export type TastingNote = { iconName: string; label: string };
export type Review = { description: ReactNode; author: Author };
export type Author = {
  name: string;
  profileImage?: StorefrontImage;
  title?: string;
  byline?: string;
};
export type Region = { name: string };
export type StorefrontImage = IGatsbyImageData | undefined;

export type Wine = {
  bottleRRP?: number;
  showRRPMixedCases: boolean;
  showTastingAndAwardsMixedCases: boolean;
  bottleImage?: StorefrontImage;
  bottleImage_?: [StorefrontImage];
  relativeSizedBottleImage?: StorefrontImage;
  awards?: Array<Award>;
  description: any;
  reviews?: Array<Review>;
  name: string;
  region?: string;
  category?: string;
  varietal?: string;
  country?: string;
  wineAttributes: WineAttributes;
  tastingNotes?: Array<TastingNote>;
  quantity: number;
  isSpirit?: boolean;
  bottomDescription?: string | ReactNode;
  [key: string]: any;
};

export type WineAttributes = {
  vintage: string;
  style: string;
  cellaring: string;
  alcoholByVolume: number;
  closure: "Cork" | "Screwcap" | "Crown" | "Vinolok" | "Wax-dipped cork";
  servingTemp: string;
  blendInfo: string;
  preservatives: string[];
  palateWeight: "Full-bodied" | "Medium-bodied" | "Light-bodied";
  texture: string;
  intensity: string;
};

export type ResponsiveValue<T> = T | T[] | { [key: string]: T };

const stackSpaceValues = [0, 1, 2, 3, 4, 5, 6, 7] as const;
export type StackSpace = typeof stackSpaceValues[number];

export type DeliveryStatus =
  | "processing"
  | "delivered"
  | "picked"
  | "returned"
  | "paid";

export type OrderItemOffer = VinoDataOffer & {
  bigcommerceId: string;
  name: string;
  image?: StorefrontImage;
  imageUrl?: string;
  sku: VinoDataSKU;
  secret: boolean;
};

export type OrderItem = {
  offer?: OrderItemOffer;
  name: string;
  trackingLink?: string;
  qty: number;
  totalPrice: number;
  productId: number;
  name_merchant?: string;
  sku?: string;
  skuType?: string;
};

export type Order = {
  id: number;
  date: Date;
  statusId: number;
  deliveryStatus: DeliveryStatus;
  orderNumber: string;
  refundedAmount?: number;
  giftCertificateAmount?: number;
  creditAmount?: number;
  discountAmount?: number;
  shippingPrice?: number;
  subtotalPrice?: number;
  totalPrice: number;
  totalTax: number;
  items: Array<OrderItem>;
  shippingAddress?: Address;
  paymentMethod: string;
  transactionLast4digit?: string;
  points?: number;
};

export const orderStatusMapping = {
  Incomplete: "processing",
  Pending: "processing",
  Shipped: "shipped",
  "Partially Shipped": "picked",
  Refunded: "returned",
  Cancelled: "processing",
  Declined: "processing",
  "Awaiting Payment": "processing",
  "Awaiting Pickup": "picked",
  "Awaiting Shipment": "picked",
  Completed: "delivered",
  "Awaiting Fulfillment": "paid",
  "Manual Verification Required": "processing",
  Disputed: "processing",
  "Partially Refunded": "partially_returned",
} as const;

export type customAddressFields = { name?: string; value?: string };

export type Address = {
  id?: number;
  firstName: string;
  lastName: string;
  phone: string;
  company?: string;
  line_1: string;
  line_2?: string;
  city: string;
  region: string;
  postal_code: string;
  country: string;
  country_code?: string;
  form_fields?: customAddressFields[];
  delivery_instructions?: string;
  delivery_time?: string;
};

export type Club = {
  name: string;
  image: StorefrontImage;
  cost: number;
  type: string;
  dispatch_date?: Date;
  variant: ClubVariant;
};

export type ClubVariant = "mixed" | "red" | "white";

export type ClubFrequency = "period_1" | "period_2" | "period_3";

export type ClubbPreference =
  | "default"
  | "no_chardonnay"
  | "no_sauvignon_blanc"
  | "no_pinot_noir"
  | "no_grenache";

export type ClubStatus =
  | "active"
  | "pending"
  | "waitlisted"
  | "paused"
  | "cancelled";

export type ClubSummary = {
  status: ClubStatus;
  address: Address;
  club: Club;
  charge_date?: Date;
  cancelled_date?: Date;
  card_type?: string;
  frequency: ClubFrequency;
  preference: ClubbPreference;
};

export type AccountCredit = {
  issueDate: Date;
  expiryDate: Date;
  details: string;
  totalAmount: number;
  remainingAmount: number;
};

export type CreditCard = {
  id: string;
  last4: string;
  expiryMonth: string;
  expiryYear: string;
  brand: string;
  isDefault?: boolean;
};

export const responsiveVariantsValues = [
  "sm",
  "md",
  "lg",
  "responsive",
] as const;
export type ResponsiveVariant = typeof responsiveVariantsValues[number];

export type DisplayFlags =
  | "ALMOST_GONE"
  | "HIDE_DISCOUNT"
  | "SELLING_FAST"
  | "SHOW_COUNTDOWN"
  | "SHOW_PRICEDROP"
  | "HIDE_AWARDS_AND_RATINGS"
  | "HIDE_RRP"
  | "NEW_TO_THE_FO"
  | "POPULAR_OFFER";

export type InventoryStatus =
  | "PRESELLING"
  | "AVAILABLE"
  | "SOLD_OUT"
  | "UNAVAILABLE";

export type StateT =
  | Record<string, unknown>
  | BigcommerceCommerceStateT[]
  | unknown[];

export type BigcommerceCommerceStateT = {
  id: number;
  state: string;
  state_abbreviation: string;
  country_id: number;
};

export const phoneRegEx =
  process.env.GATSBY_VM_MARKET === "SG"
    ? /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{4}\\)[ \\-]*)|([0-9]{4})[ \\-]*)*?[0-9]{4}?[ \\-]*[0-9]{4}?$/
    : /^0[23478]\d{8}$/;

export const phoneRegExFormat = /^0[23478]\d{2} \d{3} \d{3}$/;

export const AlgoliaRecommendModelEnum = Object.freeze({
  related: "related-products",
  bought: "bought-together",
});

export type Link = {
  url?: string;
  target?: string;
};

export type Route = {
  slug?: string;
  path?: string;
  slugRedirecT?: string;
};

export type LinkTo = Link & Route;

export type ShareMethod =
  | "Code Copied"
  | "Email Shared"
  | "Facebook Clicked"
  | "Twitter Clicked"
  | "LinkedIn Clicked"
  | "Referral Email Deleted"
  | "Referral Email Added"
  | "Referral Email Sent";

export enum EventType {
  Click = "click",
  View = "view",
  Conversion = "conversion",
}

export enum EventSubType {
  AddToCart = "addToCart",
  Purchase = "purchase",
}
