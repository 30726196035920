
import { useState, useEffect } from "react";
import OneSignal from "react-onesignal";
import {
  errorDataBuilder,
  ErrorNotifier,
} from "../../api/src/utils/error-notifier";
import vinoFetch from "../utils/vinoFetch";

export interface OneSignalInterface {
  initialized: boolean;
  permission: "default" | "granted" | "denied";
  subscribed: boolean;
  handleSubscribe: (externalUserId) => void;
  updateTagsOnCartChange: (item) => void;
  removeTagsWithExternalUserId: (externalUserId) => void;
}

const useOneSignal = (): OneSignalInterface => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [permission, setPermission] = useState<
    "default" | "granted" | "denied"
  >(null);
  const [subscribed, setSubscribed] = useState<boolean>(false);

  useEffect(() => {
    // OneSignal.init({
    //   appId: `${process.env.GATSBY_ONESIGNAL_APP_ID}`,
    //   allowLocalhostAsSecureOrigin: true,
    //   autoResubscribe: true,
    //   promptOptions: {
    //     slidedown: {
    //       enabled: true,
    //       autoPrompt: true,
    //       timeDelay: 10,
    //       pageViews: 2,
    //     },
    //   },
    // });
    setInitialized(true);
  }, []);

  useEffect(() => {
    OneSignal.on("subscriptionChange", (isSubscribed) => {
      setSubscribed(isSubscribed);
    });

    OneSignal.on("notificationPermissionChange", (permission) => {
      setPermission(permission.to);
      if (permission.to === "granted") {
        OneSignal.setSubscription(true);
        OneSignal.registerForPushNotifications();
        setSubscribed(true);
      } else {
        OneSignal.setSubscription(false);
      }
    });

    OneSignal.getNotificationPermission().then((permission) => {
      setPermission(permission);
    });

    OneSignal.isPushNotificationsEnabled((isEnabled) => {
      setSubscribed(isEnabled);
    });
  }, [initialized]);

  const handleSubscribe = (externalUserId) => {
    OneSignal.showNativePrompt();
    OneSignal.registerForPushNotifications();
    OneSignal.setExternalUserId("" + externalUserId);
    OneSignal.setSubscription(true);
  };

  const updateTagsOnCartChange = (item) => {
    if (!item) {
      OneSignal.sendTags({
        cart_item: "",
        cart_image: "",
        cart_update: "",
      });
      return;
    }

    let lastItem;
    if (item.lineItems && item.lineItems.length > 1) {
      lastItem = item.lineItems[item.lineItems.length - 1];
    } else {
      lastItem = item.lineItems[0];
    }

    if (lastItem) {
      OneSignal.sendTags({
        cart_item: lastItem.name,
        cart_image: lastItem.imageUrl,
        cart_update: Math.floor(Date.now() / 1000),
      });
    }
  };

  const removeTagsWithExternalUserId = async (externalUserID) => {
    try {
      await vinoFetch.get("/api/remove-user-tags-onesignal", {
        params: { externalUserId: externalUserID },
      });
    } catch (error) {
      console.error(error.message, error);
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      throw error;
    }
  };

  return {
    initialized,
    permission,
    subscribed,
    handleSubscribe,
    updateTagsOnCartChange,
    removeTagsWithExternalUserId,
  };
};

export default useOneSignal;
