import config from "../../config.json";
type ShippingPromoStatus = {
  shippingPromoMessage?: string;
  shippingTotal: number;
  gcProducts?: number;
  isGiftCardOnly?: boolean;
  isFreeShipping?: boolean;
  geoLocRegion?: string;
};

export const getShippingPromoStatus = (
  cartAmount: number,
  cartQuantity: number,
  gcProducts?: number,
  isGiftCardOnly?: boolean,
  isFreeShipping?: boolean,
  geoLocRegion?: string
): ShippingPromoStatus => {
  if (!geoLocRegion) {
    const geoLoc = localStorage.getItem("vm-geolocation");
    geoLocRegion = geoLoc ? JSON.parse(geoLoc).subdivision?.code : geoLocRegion;
  }
  const market = process.env.GATSBY_VM_MARKET || "AU";
  const configData = config[market];
  const minItems = parseInt(configData.MIN_ITEMS_FOR_FREE_SHIPPING) || 3;
  const minValue = parseInt(configData.MIN_VALUE_FOR_FREE_SHIPPING) || 300;
  const shippingCost = isFreeShipping
    ? 0
    : geoLocRegion &&
      geoLocRegion !== "" &&
      parseFloat(configData.DEFAULT_SHIPPING_AMOUNT_PER_REGION[geoLocRegion])
    ? parseFloat(configData.DEFAULT_SHIPPING_AMOUNT_PER_REGION[geoLocRegion])
    : parseFloat(configData.DEFAULT_SHIPPING_AMOUNT) || 9.9;

  const isMixed = !isGiftCardOnly && gcProducts > 0;
  const updatedQuantity = isMixed ? cartQuantity - gcProducts : cartQuantity;

  if (market === "AU") {
    const shippingTotal = updatedQuantity >= minItems ? 0 : shippingCost;
    const itemsRemaining = Math.max(0, minItems - updatedQuantity);
    const plural = itemsRemaining !== 1 ? "s" : "";
    return {
      shippingPromoMessage:
        itemsRemaining > 0 ? `add ${itemsRemaining} more case${plural}` : null,
      shippingTotal,
    };
  }

  if (market === "SG") {
    const formatter = new Intl.NumberFormat("en-SG", {
      style: "currency",
      currency: "SGD",
    });

    const shippingTotal = cartAmount >= minValue ? 0 : shippingCost;
    const valueRemaining = Math.max(0, minValue - cartAmount);
    const [wholeAmount, fractionAmount] = formatter
      .format(valueRemaining)
      .split(".");
    const formattedValue = `${wholeAmount}${
      fractionAmount !== "00" ? "." + fractionAmount : ""
    }`;

    return {
      shippingPromoMessage:
        valueRemaining > 0 ? `just spend ${formattedValue} more` : null,
      shippingTotal,
    };
  }

  return {
    shippingTotal: shippingCost,
  };
};
