export interface InstantSearchState {
  error?: Error;
  searchState: any;
  searching: boolean;
  hasResult: boolean;
  carryOverFilter?: string;
}

export const initialState: InstantSearchState = {
  searchState: null,
  searching: false,
  hasResult: false,
  carryOverFilter: "",
};
