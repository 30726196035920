import {
  ErrorNotifier,
  errorDataBuilder,
} from "../../../api/src/utils/error-notifier";
import vinoFetch from "../../utils/vinoFetch";

interface LoyaltyHandler {
  getPromotionByCode: (couponCode) => Promise<any>;
  getLoyaltyConfig: () => Promise<any>;
}

export const LoyaltyResolver: LoyaltyHandler = {
  getPromotionByCode: async (couponCode): Promise<any> => {
    try {
      const {
        data: { data: promotionData },
      } = await vinoFetch.get("/api/get-promotion-by-code", {
        params: {
          code: couponCode,
        },
      });
      return promotionData;
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  getLoyaltyConfig: async (): Promise<any> => {
    try {
      const { data } = await vinoFetch.get("/api/get-loyalty-config");
      return data;
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
};
