import { createContext } from "react";

import { LoyaltyState, initialLoyaltyState } from "./state";
import { LoyaltyConfig } from "./types";

export interface LoyaltyContextInterface extends LoyaltyState {
  findCoupon: (code: string) => Promise<any>;
  getLoyaltyConfig: () => Promise<LoyaltyConfig>;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <LoyaltyProvider>.");
};

const initialContext = {
  ...initialLoyaltyState,
  findCoupon: stub,
  getLoyaltyConfig: stub,
};

export const LoyaltyContext = createContext<LoyaltyContextInterface>(
  initialContext
);

export default LoyaltyContext;
