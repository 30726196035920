import { LoyaltyState } from "./state";

export const reducer = (
  state: LoyaltyState,
  action: { type: string } & LoyaltyState
): LoyaltyState => {
  switch (action.type) {
    case "CHANGE_TIER":
      return { ...state, tier: action.tier };
    case "SET_CONFIG":
      return { ...state, config: action.config };

    default:
      return state;
  }
};
