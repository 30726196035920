import { useQuery } from "@tanstack/react-query";
import vinoFetch from "../utils/vinoFetch";

export type RequestParams = {
  url: string;
  headers?: Record<string, string>;
  queryStringParams?: Record<string, string>;
  getAccessToken?: () => Promise<string>;
  getTokenID?: () => Promise<string>;
};

type ResponseData = Record<string, unknown> | Array<unknown>;
type ResponseError = Record<string, unknown>;
type Response<TData extends ResponseData, TError extends ResponseError> = {
  data: TData;
  error: TError;
  isLoading: boolean;
  mutate;
};

type FetcherParams = Required<Omit<RequestParams, "getAccessToken" | "getTokenID">> & {
  getAccessToken?: () => Promise<string>;
  getTokenID?: () => Promise<string>;
};

export const fetcherDefaults: Omit<FetcherParams, "url"> = {
  headers: {},
  queryStringParams: {},
} as const;

export const useRequest = (params?: RequestParams) => {
  const { key, fetcher } = createFetcher({ ...fetcherDefaults, ...params });
  const { data, error, isError, isSuccess, isLoading, refetch } = useQuery({ queryKey: [key], queryFn: () => fetcher() });

  return {
    data,
    isSuccess,
    error: isError ? error.message : null,
    isLoading,
    refetch
  };
};

export const createFetcher = (params: FetcherParams): { key: string; fetcher: any } => {
  const { url, headers, queryStringParams, getAccessToken, getTokenID } = params;

  const addAuthHeaders = async () => {
    let token: string | undefined;

    // Prioritize getAccessToken over getTokenID if both exist
    if (typeof getAccessToken === 'function') {
      token = await getAccessToken();
    } else if (typeof getTokenID === 'function') {
      token = await getTokenID();
    }

    // Set Authorization header if token exists
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  };

  return {
    key: vinoFetch.getUri(url, { params: queryStringParams }),
    fetcher: async () => {
      // If getAccessToken or getTokenID is provided, ensure Authorization header is set
      if (typeof getAccessToken === 'function' || typeof getTokenID === 'function') {
        await addAuthHeaders();

        // Ensure Authorization header is properly set before proceeding
        const authorizationHeader = headers["Authorization"]?.split(' ')[1];

        if (authorizationHeader) {
          const response = await vinoFetch.get(url, { params: queryStringParams, headers });
          return response.data;
        } else {
          throw new Error("Required Authorization header is missing");
        }
      } else {
        // Proceed without auth headers if neither getAccessToken nor getTokenID is provided
        const response = await vinoFetch.get(url, { params: queryStringParams, headers });
        return response.data;
      }
    },
  };
};
