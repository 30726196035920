import { LoyaltyTier } from "./types";

export interface LoyaltyState {
  tier: LoyaltyTier;
  config: any;
  findCoupon?: (code: string) => void;
}

export const initialLoyaltyState: LoyaltyState = {
  tier: "GREEN",
  config: null
};
