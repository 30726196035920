/* istanbul ignore file */

declare global {
  interface Window {
    datadogLogs: {
      logger: {
        info: (message: string, context?: Record<never, never>) => void;
        error: (message: string, context?: Record<never, never>) => void;
      };
    };
  }
}

const dataDogDefined = (): boolean =>
  null !== window.datadogLogs && undefined !== window.datadogLogs;

export const logInfo = (
  message: string,
  context?: Record<never, never>
): void =>
  dataDogDefined()
    ? window.datadogLogs.logger.info(message, context)
    : console.log(message, context);

export const logError = (
  message: string,
  context?: Record<never, never>
): void =>
  dataDogDefined()
    ? window.datadogLogs.logger.error(message, context)
    : console.error(message, context);
