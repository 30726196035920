interface Promotion {
  id: number;
  redemption_type: string;
  name: string;
  channels: { id: number }[];
  customer: {
    group_ids: number[];
    minimum_order_count: number;
    excluded_group_ids: number[];
    segments: { id: string[] };
  };
  rules: {
    action: {
      cart_value: {
        discount: {
          fixed_amount: string;
        };
      };
    };
    apply_once: boolean;
    stop: boolean;
    condition: {
      cart: {
        items: {
          brands: number[];
        };
        minimum_spend: string;
        minimum_quantity: number;
      };
    };
  }[];
  current_uses: number;
  max_uses: number;
  status: string;
  start_date: string;
  end_date: string;
  stop: boolean;
  can_be_used_with_other_promotions: boolean;
  currency_code: string;
  notifications: {
    content: string;
    type: string;
    locations: string[];
  }[];
  shipping_address: {
    countries: { iso2_country_code: string }[];
  };
  schedule: {
    week_frequency: number;
    week_days: string[];
    daily_start_time: string;
    daily_end_time: string;
  };
}

export const isCouponForLoggedInUser = (promition: Promotion): boolean =>
  (promition?.customer?.group_ids &&
    promition?.customer?.group_ids?.length > 0 &&
    !promition?.customer?.group_ids?.includes(0)) ||
  (promition?.customer?.excluded_group_ids &&
    promition?.customer?.excluded_group_ids?.length > 0 &&
    promition?.customer?.excluded_group_ids?.includes(0));
