import { LoyaltyContext, LoyaltyContextInterface } from "./context";
import { useEffect, useReducer } from "react";

import { LoyaltyResolver } from "./loyalty-resolver";
import { PropsWithChildren } from "react";
import contextLogger from "../../utils/context-logger";
import { initialLoyaltyState } from "./state";
import { reducer } from "./reducer";

interface LoyaltyProviderProps {}
const LoyaltyProvider = ({
  children,
}: PropsWithChildren<LoyaltyProviderProps>): JSX.Element => {
  let defaultState = initialLoyaltyState;
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === "development" ? contextLogger(reducer) : reducer,
    defaultState
  );

  const resolver = LoyaltyResolver;

  const findCoupon = (code: string): Promise<any> => {
    return resolver.getPromotionByCode(code);
  };
  const getLoyaltyConfig = async () => {
    const data = await resolver.getLoyaltyConfig();
    dispatch({ type: "SET_CONFIG", config: data });
  };

  useEffect(() => {
    const isLoyaltyFeatureEnabled = process.env.GATSBY_LOYALTY_ENABLED === "true";
    if(isLoyaltyFeatureEnabled) getLoyaltyConfig();
  }, []);

  const ctx: LoyaltyContextInterface = {
    ...state,
    findCoupon,
  };
  return (
    <LoyaltyContext.Provider value={{ ...ctx }}>
      {children}
    </LoyaltyContext.Provider>
  );
};

export default LoyaltyProvider;
