
interface VinoResponse extends Response {
    data?: any;
    status: number
}

const defaultHeaders = {
    "Content-Type": "application/json",
    "Accept": "application/json",
};

export const handleResponse = async(response: VinoResponse) => {
    const apiResponsePromise = await response;
    const { status } = apiResponsePromise;

    if(apiResponsePromise.status === 204) {
        return {
            status,
            data: await response.text()
        };
    }

    if (!apiResponsePromise.ok || apiResponsePromise.status >= 400) {
        const responseData = await response.json();
        const data = { ...responseData };
        throw {
            status,
            response: { data, status }
        }
    } else {
        return {
            status,
            data: await response.json(),
        };
    }
};


export const post = async(url: string, data: any, config?: { headers: Record<string, string>}) => {
  return fetch(url, {
      headers: {
        ...defaultHeaders,
        ...config?.headers,
      },
      method: "POST",
      referrerPolicy: 'origin',
      mode: 'cors',
      body: JSON.stringify(data),
    }).then(async (response) => await handleResponse(response));
};

export const get = async(rawUrl: string, config?: { params?: Record<string, any>, headers?: Record<string, string>}) => {
    const hasParams = config?.params && Object.values(config.params).length > 0;
    const url = hasParams ? rawUrl + '?' + new URLSearchParams(config?.params).toString() : rawUrl;
    return fetch(url, {
        headers: {
            ...defaultHeaders,
            ...config?.headers,
        },
        referrerPolicy: 'origin',
        mode: 'cors',
        method: "GET",
    })
    .then(async (response) => await handleResponse(response));
}

export const getUri = (rawUrl: string, config?: { params?: Record<string, any>, headers?: Record<string, string>}) => {
    const hasParams = Object.keys(config.params).length > 0;
    const formattedUrl = hasParams ? rawUrl + '?' + new URLSearchParams(config?.params).toString() : rawUrl;
    return formattedUrl;

}

export const put = async(url: string, data: any, config?: { headers: Record<string, string>}) => { 
return fetch(url, {
        headers: {
            ...defaultHeaders,
            ...config?.headers,
        },
        referrerPolicy: 'origin',
        mode: 'cors',
        method: "PUT",
        body: JSON.stringify(data),
    })
    .then(async(response) => await handleResponse(response));
}

export const deleteRequest = async(url: string, config?: { data: any, headers: Record<string, string>}) => { 
    return fetch(url, {
        headers: {
            ...defaultHeaders,
            ...config?.headers,
        },
        referrerPolicy: 'origin',
        mode: 'cors',
        method: "DELETE",
        body: JSON.stringify(config?.data),
    })
    .then(async(response) => await handleResponse(response));
}

export default { post, get, put, deleteRequest, getUri };