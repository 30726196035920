import { LoyaltyTier, TierNames } from "../contexts/loyalty/types";

export const BIG_COMMERCE_CART_ID_COOKIE = "vm-bigcommerce-cart-id";
export const GUEST_CART_EMAIL_COOKIE = "vm-guest-cart-email";

export const GUEST_CHECKOUT_PATH = "/guest-checkout/";

export const MARKET_COUNTRIES = {
  AU: "Australia",
  SG: "Singapore",
};

export const MARKET_AUDIENCE = {
  ALL: "AU, NZ, SG",
  EXCEPTSG: "AU, NZ",
  SG: "SG",
  AU: "AU",
};

export enum EDevice {
  BOTH = "both",
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

export const DEVICE = {
  BOTH: EDevice.BOTH,
  DESKTOP: EDevice.DESKTOP,
  MOBILE: EDevice.MOBILE,
};

export const INVALID_ADDRESS_MSG =
  "Something wrong with the address, please check suburb, postcode or state if they are matching";

export const PROMOTIONS_BANNER_TYPES = {
  UPSELL: "upsell",
  ELIGIBLE: "eligible",
  APPLIED: "applied",
};

export const loyaltyTierNames: TierNames = {
  GREEN: "GREEN",
  SILVER: "SILVER",
  GOLD: "GOLD",
  PLATINUM: "PLATINUM",
  FOUNDERS: "FOUNDERS",
};

export const loyaltyTiers: LoyaltyTier[] = [
  loyaltyTierNames.GREEN,
  loyaltyTierNames.SILVER,
  loyaltyTierNames.GOLD,
  loyaltyTierNames.PLATINUM,
  loyaltyTierNames.FOUNDERS,
];

export const refundStatus = [4, 14];

export const discountDisplays = {
  percent: "percentage",
  dollar: "dollar_value",
};
