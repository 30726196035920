import { Address, StoredInstrument } from "../../../api/src/types";

import { DataItem } from "../../../api/src/services/dynamodb";
import { User } from "@auth0/auth0-react";
import isBrowser from "../../utils/is-browser";

export type Tiers =
  | "GREEN"
  | "SILVER"
  | "GOLD"
  | "PLATINUM"
  | "FOUNDER"
  | "ALL";
export interface VinoUser extends User {
  createdate?: string;
  firstname?: string;
  lastname?: string;
  date_of_birth?: string;
  email?: string;
  email_verified?: boolean;
  nickname?: string;
  phone?: string;
  birthdate?: string;
  picture?: string;
  sub?: string;
  user_id?: string;
  "https://www.vinomofo.com/user_metadata"?: { [key: string]: string };
  "https://www.vinomofo.com/app_metadata"?: { [key: string]: string };
  customer_roles?: Array<string>;
  customer_group?: CustomerGroup;
  addresses?: Array<Address>;
  stored_instruments?: Array<StoredInstrument>;
  loyalty_tier: Tiers;
}

export interface CustomerGroup {
  id?: number;
  name?: string;
  is_default?: boolean;
  category_access?: {
    type?: string;
    categories?: Array<number>;
  };
  discount_rules?: Array<{
    type?: string;
    method?: string;
    amount?: string;
    price_list_id?: string;
    categories?: Array<number>;
  }>;
  is_group_for_guests?: boolean;
}

export interface AuthState {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  checkingReferralCode: boolean;
  user?: VinoUser;
  customerReferralCode: string;
  fetchingFavourites: boolean;
  favourites?: Array<DataItem>;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: isBrowser(),
  checkingReferralCode: true,
  customerReferralCode: "",
  fetchingFavourites: true,
  favourites: [],
};
